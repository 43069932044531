import { useModule } from "@/hooks";
import { IModuleThreeApiResponse, IModuleThreeDTO } from "../../interfaces/IModuleThreeDTO";
import { AirlinesFilterPanel, ServicesFilterPanel } from "..";

import DestinationFlightFilterPanel from "../DestinationFlightFilterPanel/DestinationFlightFilterPanel";
import IspirationTravelFlights from "../IspirationTravelFlights/IspirationTravelFlights";
import FormSelector from "../Form/FormSelector";
import NewsCategory from "./NewsCategory";
import ContactUs from "./ContactUs";
import FlightDetail from "./FlightDetail";
import ShopLayoutPage from "./ShopLayoutPage";

interface IModuleThreeWrapperProps {
    targetUrl: string,
    data: IModuleThreeApiResponse | null,
    language: string
}

const ModuleThreeWrapper: React.FC<IModuleThreeWrapperProps> = ({ targetUrl, data, language }: IModuleThreeWrapperProps) => {

    var moduleData: IModuleThreeApiResponse;

    if (targetUrl) {

        moduleData = useModule<IModuleThreeApiResponse>(targetUrl);
    } else {
        
        moduleData = data as IModuleThreeApiResponse;
    }

    const switchCategory = (category: IModuleThreeDTO) => {
        
        switch (category?.enuTypeInfo) {
            case "news_in_evidenza":
                return <NewsCategory
                    language={language}
                    data={{
                        grpTypology: category?.grpTypology,
                        flgAllNews: category?.flgAllNews,
                        xtaxNewsCategory: category?.xtaxNewsCategory,
                        newsId: category?.xconNews?.id,
                    }} />
            case "contattaci" :
                return null;
                // return <ContactUs
                //     language={language}
                //     data={{
                //         grpTypology: category?.grpTypology
                //     }}  />
            case "volo_dettaglio_arrivi_MXP":
            case "volo_dettaglio_arrivi_LIN":
            case "volo_dettaglio_partenze_MXP":
            case "volo_dettaglio_partenze_lin":
                return <FlightDetail 
                    language={language}
                    hostPageType={moduleData?.data?.enuTypeInfo} />
            case "servizi-MXP":
            case "servizi-LIN":
                return <ServicesFilterPanel />

            case 'voli_destinazioni_da_MXP':
            case 'voli_destinazioni_da_LIN':
                return category.grpTypology === 'grpTravelInspirationFlights' ? <IspirationTravelFlights partnerShipActive={moduleData?.data?.flgActivePartnership}  language={language} destination={category.xtaxDestinations?.ti?.label} /> : <DestinationFlightFilterPanel moduleData={moduleData?.data} />

            case 'voli_compagnie-aeree_MXP':
            case 'voli_compagnie-aeree_LIN':
                return <AirlinesFilterPanel />
            case "widget_form":
                return <FormSelector language={language} type={category} formId={category.xconWidgetForm.id}/>
            
            case 'negozio-MXP-AFM' : case 'ristorantebar-MXP-AFM' :
                return <ShopLayoutPage language={language}/>
   
            default:
                return null
        }
    }

    return (
        <>{switchCategory(moduleData?.data)}</>
    )
}

export default ModuleThreeWrapper;
