import { useIsMobile, useOutsideClick } from "@/hooks";
import { useRecoilState, useSetRecoilState } from "recoil";
import { hoveredElementIdAtom } from "@/atoms/NavigationAtoms";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { IFirstLevelNavItem, INavigationDTO } from "@/interfaces/INavigationDTO";
import { MegaMenu, MobileSearchMenu, MobileSideMenu, Navigation, SearchMenu } from "@/components/index"
import { SearchMenuAtom } from "../../atoms";
import { useTranslation } from "react-i18next";

interface INavigationProps {
    navigationData: INavigationDTO,
    hostPageData?: any,
    language: string
}

interface ICategoryDTO {
    id: INavigationDTO,
    custom_url: { url: string },
    description: string,
    name: string,
    is_visible: boolean,
    parent_id: number
}

const NavigationMenu: React.FC<INavigationProps> = ({ navigationData, hostPageData = null, language }: INavigationProps) => {

    const { i18n } = useTranslation();
    const lang = language? language : i18n.language;

    const setselectedElementId = useSetRecoilState(hoveredElementIdAtom)
    const headerRef = useRef<null | HTMLDivElement>(null);
    const [isMobile] = useIsMobile()
    const [categoryData, setCategorydata] = useState<ICategoryDTO[]>();
    const [taxonomyItem] = navigationData?.data;

    const imageObj = useMemo(() => {
        let imageObj = taxonomyItem.ti.find((item: any) => item.label?.toLowerCase() === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE?.toLowerCase()).xtaxAirport?.ti?.upiLogoBlack;
        return {
            path: imageObj?.mediaserverpath + "/" + imageObj?.resourcename,
            description: "",
            alt: ""
        }
    }, [navigationData])

    const navItems: IFirstLevelNavItem[] = taxonomyItem.ti.find((item: any) => item.label?.toLowerCase() === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE?.toLowerCase()).ti;
    const [isSearchOpen, setIsSearchOpen] = useRecoilState(SearchMenuAtom);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen)
    }

    async function getHasher() {
        return await fetch (
            `${process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER}${process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID}`);
    }

    async function getCategoryData(hash: string) {

        if (process.env.NEXT_PUBLIC_BIG_COMMERCE_TOKEN) {
                
            return await fetch(

                `${process.env.NEXT_PUBLIC_BIG_COMMERCE_BASE_PATH}/categories`, {
                    method: 'GET',
                    headers: ({
                        'x-auth-hash': hash,
                        'x-auth-instance-id': process.env.NEXT_PUBLIC_BIG_COMMERCE_HASHER_UUID!
                    })
                }
            )
        }               
    }

    useEffect(() => {

        // getHasher()
        // .then(response => response?.json())
        // .then((response) => {

        //     let hash = response.hash;
            
        //     getCategoryData(hash)
        //         .then(response => response?.json())
        //         .then(response => {
                    
        //             setCategorydata(response.filter((item: any) => {return item.parent_id === 24}));
        //         }).catch(error => 
                    
        //             console.error('[ShopLayoutPage] getCategoryData error -> ', error)
        //         );
        // })
        // .catch(error => {

        //     console.error('[ShopLayoutPage] getHasher error -> ', error);
        // })   
    }, []);

    function localizeCategoryEn(categories: any) {

        categories?.map((category: ICategoryDTO) => {
            switch(category.name) {
                case 'Borse': {
                    category.name = 'Bags';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                } 
                case 'Orologi': {
                    category.name = 'Watches';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
                case 'Profumi': {
                    category.name = 'Fragrances';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
                case 'Beauty': {
                    category.custom_url.url = '/en/beauty';
                    return category;
                }
                case 'Accessori': {
                    category.name = 'Accessories';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
                case 'Alcolici': {
                    category.name = 'Spirits';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
                case 'Food': {
                    category.custom_url.url = '/en/food';
                    return category;
                }
                case 'Abbigliamento': {
                    category.name = 'Clothing';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
                case 'Gioielli e bigiotteria': {
                    category.name = 'Jewelry';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
                case 'Scarpe': {
                    category.name = 'Shoes';
                    category.custom_url.url = '/en' + category.custom_url.url;
                    return category;
                }
            }
        })

        return categories;
    }

    useOutsideClick(headerRef as MutableRefObject<HTMLDivElement>, () => { setIsSearchOpen(false); setselectedElementId(null) });


    return (
        <header ref={headerRef} className='relative mx-auto max-w-cont-1 w-full shadow-[0_-5px_3px_-3px_lightgrey,0_5px_3px_-3px_lightgray]'>
            <Navigation language={lang} onSearchClick={toggleSearch} hostPageData={hostPageData} navItems={navItems} imageUrl={imageObj} />
            <MegaMenu language={lang} navItems={navItems} categoryData={lang == 'it' ? categoryData : localizeCategoryEn(categoryData)}/>
            {isSearchOpen && !isMobile ? <SearchMenu /> : null}
            <MobileSearchMenu language={lang}  isMobile={isMobile} imageUrl={imageObj} />
            <MobileSideMenu language={lang} hostPageData={hostPageData} menuItems={navItems} imageUrl={imageObj} categoryData={lang == 'it' ? categoryData : localizeCategoryEn(categoryData)}/>
        </header>
    )
};

export default NavigationMenu;